<template>
<!-- 合作方式 -->
  <div class="about">
    <div class="nuox-about-header">
      <img src="http://39.104.95.82:8087/img/header/2.jpg" >
    </div>
    <div class="module-content">
      <div class="nuox-breadcrumb">
        <span class="nuox-breadcrumb-item"><router-link tag="span" :to="$t('lang.headerMenus[0].pageSrc')">{{ $t("lang.headerMenus[0].name") }}</router-link></span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[3].name") }}</span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[3].childrens[1].name") }}</span>
      </div>
      <div class="module-line"></div>
        <div :class="$i18n.locale=='en'?'module-nav-en':'module-nav'">
          <ul class="menu-vertical-nav">
            <li v-for="(item,index) in menus" :key="index" @click="checknav(item)">
              <div :class="'title '+[item.id==checkId?'open':'']" ><span>{{item.name}}</span><span v-if="item.childrens&&item.childrens.length>0"  :class="[item.id==checkId?'el-icon-arrow-down':'el-icon-arrow-right']+ ' icon-down'"></span></div>
              <dl v-if="item.childrens && item.id==checkId && item.childrens.length>0">
                <dd :class="[item2.id==checkChildrenId?'in':'']" v-for="(item2,index2) in item.childrens" :key="index2" @click="checknavchildren(item,item2,index2)">{{item2.name}}</dd>
              </dl>
            </li>
          </ul>
        </div>
        <div class="nuox-module">
          <div class="nuox-content-title">
            <div class="title-name">
              {{ $t("lang.headerMenus[3].childrens[1].name") }}
              <div class="xian"></div>
            </div>
          </div>
          <div class="nuox-module-cooperate">
            <el-row style="height:150px">
                <el-col :span="item.span" v-for="(item,index) in list" :key="index">
                  <div class="way-model bg-cyan">
                    <img v-if="item.isShow==1" :src="item.imgSrc">
                    <div class="con" v-if="$i18n.locale=='zh'"><div class="xian"></div>{{item.modeNameZh}}</div>
                    <div class="con" v-if="$i18n.locale=='en'"><div class="xian"></div>{{item.modeNameEn}}</div>
                  </div>
                </el-col>
            </el-row>
            <div class="module-line" style="margin-top:20px"></div>
            <div v-for="(item,index) in list" :key="index" style="margin-bottom:50px">
                <div :id="'nuox-way-'+index"></div><!-- 锚点 -->
                <div class="nuox-title-two" v-if="item.modeNameViceZh!=''" style="margin-top:50px;margin-bottom:25px">
                  <i class="iconfont icon-shuxian"></i>
                  <span v-if="$i18n.locale=='zh'">{{item.modeNameViceZh}}</span>
                  <span v-if="$i18n.locale=='en'">{{item.modeNameViceEn}}</span>
                  <i class="iconfont icon-shuxian"></i>
                </div>
                <div  v-if="$i18n.locale=='zh'" v-html="item.modeContactZh"></div>
                <div  v-if="$i18n.locale=='en'" v-html="item.modeContactEn"></div>
            </div>
          </div>
        </div>
      <div class="module-line"></div>
    </div>
  </div>
</template>
<script>
export default {
  computed:{
    menus:function(){
      return this.$t('lang.headerMenus[3].childrens');
    }
  },
  data:function() {
    return {
        checkId:32,
        checkChildrenId:0,
        list:[]
    };
  },
  mounted(){
    this.init();
  },
  methods:{
    async init(){//查询合作方式
      var that = this;
        var baseUrl = this.$baseURL;
      const { data: res } = await this.$http.get(
        "webSite/nuox_mode?pageNum=1&pageCount=3&modeType=hzfs"
      );
      if(res.code==0){
        var data = res.data.data;
        var list =[];
        for(var key in data){
          var item = {};
          var imgSrc = '';
              if(data[key].attList&&data[key].attList.length>0){
                imgSrc = baseUrl+data[key].attList[0].attPath+data[key].attList[0].attRealName
              }
          item.imgSrc = imgSrc;
          item.modeNameZh = data[key].modeNameZh;
          item.modeNameEn = data[key].modeNameEn;
          item.modeNameViceZh = data[key].modeNameViceZh;
          item.modeNameViceEn = data[key].modeNameViceEn;
          item.modeIntroductionZh = data[key].modeIntroductionZh;
          item.modeIntroductionEn = data[key].modeIntroductionEn;
          item.modeContactZh = data[key].modeContactZh;
          item.modeContactEn = data[key].modeContactEn;
          item.isShow = data[key].isShow;
          if(key==1){
            item.span = 10
          }else{
            item.span = 7
          }
          list.push(item);
        }
        that.list=list;
      }
    },
    checknav(e){
      if(e.pageSrc){
          this.$router.push({ 
            path: e.pageSrc,
            query: {}
          })
      }
      this.checkId = e.id;
    },
    checknavchildren(item,item2,index){
      document.getElementById("nuox-way-"+index).scrollIntoView({
          behavior: "smooth",  // 平滑过渡
          block:    "start"  // 上边框与视窗顶部平齐。默认值
      });
      this.checkId=item.id;
      this.checkChildrenId=item2.id;
    }
  }
}
</script>
<style>
@import '../../assets/css/about.css';
.way-model{
  background-color: #00448F;
  height: 130px;
  width: 100%;
  position: relative;
   display: flex;
   align-items: center;
      justify-content: center;
      text-align: center;
}
.way-model .con{
    position:absolute;
    color: #fff;
    font-size: 12px;
     font-family: "Microsoft YaHei";
  font-weight: bold;
  letter-spacing: 2px;
}
.way-model img{
    width: 100%;
    height: 100%;
}
.way-model .xian{
    margin: 5px auto;
    margin-bottom: 10px;
    width: 20px;
    height: 4px;
    background-color: #fff;
}
</style>